<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a Topup</p>

        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a user"
            ></v-select>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedZoneFilter"
              ref="selectedZoneFilter"
              outlined
              dense
              item-value="id"
              item-text="info"
              :items="zone_types"
              label="Filter Clients By Zone"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field v-model="recruitmentDate" ref="recruitmentDate" type="date" outlined dense label="Recruitment Date"></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <vue-editor ref="topupDescription" v-model="topupDescription" />
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-text class="text-right">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="createTopup()"
        >
          Create TopUp
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";
import { VueEditor } from "vue2-editor";


export default {
  components: {
    Spinner,
    VueEditor,
  },

  data() {
    return {
      loading: false,
      userName: '',
      selectedUser: '',
      users: [],
      zone_types: [],
      selectedZoneFilter: "",
      topupDescription: "",
      recruitmentDate: "",
    }
  },
  mounted(){
    document.title = "Manager: Savings | Create Topup"
    this.getZones()
  },

  methods:{
    async getZones(){
      await axios
        .get('/api/v1/manager/get/topup/zones/')
        .then(response => {
          this.zone_types = response.data  // get the data and fill into campaigns
          console.log(this.zone_types)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getUsers(){
      axios
        .get('/api/v1/manager/get/clients/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async createTopup(){
      if(this.selectedZoneFilter.length < 5){
          this.$store.commit('setSnackBarMessage', "Please select the topup's zone")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedZoneFilter.focus()  // this causes a an auto fucos to the element
      }
      else if(!this.selectedUser || this.selectedUser === ""){
              this.$store.commit('setSnackBarMessage', "Please select a user")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.selectedUser.focus()  // this causes a an auto fucos to the element
      }
      else if(this.topupDescription.length == 0){
          this.$store.commit('setSnackBarMessage', "Please describe the topup user")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.topupDescription.focus()  // this causes a an auto fucos to the element
      }
      else if(this.recruitmentDate.length == 0){
          this.$store.commit('setSnackBarMessage', "Please choose topup recruitment date")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.recruitmentDate.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
  
        let formData = new FormData()
        formData.append('user_id', this.selectedUser)
        formData.append('zone_id', this.selectedZoneFilter)
        formData.append('topup_description', this.topupDescription)
        formData.append('recruitment_date', this.recruitmentDate)

        await axios
            .post('/api/v1/manager/create/topup/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                console.log(response.data)
                this.$store.commit('setSnackBarMessage', response.data[0].text)
                this.$store.commit('setSnackBarColor', response.data[0].color)
                this.$store.commit('activateSnackBar', true)
                this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                if(response.data[0].category === "success"){
                  let router = this.$router
                  setTimeout(function(){
                    router.push('/savings/topup/summary')
                  }, 2500)
                }
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })

        this.loading = false
        }

    },

  },
}
</script>
